import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

function About() {
  const maxWidth = useSelector((state) => state.maxWidth)

  useEffect(() => {
    document.title = 'About - jonghwi kim'
  }, [])

  return (
    <>
      <Navbar />
      <div className='profile flex justify-center align-center flex-col-reverse lg:flex-row w-10/12 lg:w-9/12 lg:pt-16 pt-10 mx-auto relative lg:justify-between'>
        <div className='profile-info lg:pr-10 lg:w-1/2 pt-8 lg:pt-0'>
          <h2 className='text-lg font-bold'>
            Looking for a Web Developer Who Cares About More Than Just Code?
          </h2>
          <div className='text-xs sm:text-sm text-gray-600 space-y-4 mt-4'>
            <p>
              Hi, I’m Jonghwi, a developer who sees a website as more than just
              code—it’s a powerful way to bring in real business. With
              experience in web development, SEO, and PPC, I build sites that
              don’t just look good; they bring in calls, foot traffic, and
              paying customers.
            </p>
            <p>
              Through my part-time business,{' '}
              <a
                href='https://webtamin.co/'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500'
              >
                Webtamin
              </a>
              , I’ve helped small businesses, especially auto repair shops, show
              up in local searches, attract more customer inquiries, and drive
              steady growth.
            </p>
            <p>
              <b>
                My focus is simple: helping you get seen by the right people,
                turning visitors into paying customers, and making your website
                a true business tool.
              </b>
            </p>
            <p>
              Every site I build is crafted to deliver results: SEO that pushes
              you higher in search rankings, easy-to-navigate designs that keep
              visitors engaged, and PPC strategies that reach people ready to
              act.
            </p>

            <p>
              If you’re looking for a developer who understands what a website
              can really do for your business, let’s talk! I’d love to bring
              this results-driven approach to your team.
            </p>
          </div>

          <div className='flex mt-12 mb-8'>
            <a
              className='btn text-xs sm:text-sm rounded-none border border-black bg-transparent text-black hover:bg-transparent'
              href='https://docs.google.com/document/d/1mRocp_GtfJ9TTSFG2qkIQXhZl8M0tdVPrm0kn9cbtn4/edit?usp=drive_link'
              target='_blank'
              rel='noreferrer noopener'
            >
              view my resume
            </a>
            <a
              className='btn text-xs sm:text-sm rounded-none border-0 bg-black text-white hover:bg-black ml-2'
              href='mailto: johnnykim94@hotmail.com'
              target='_blank'
              rel='noreferrer noopener'
            >
              contact me
            </a>
          </div>
        </div>

        <div className='max-w-screen-sm w-4/5 mx-auto lg:w-full lg:m-0'>
          <img
            className='profile-img relative'
            src={`${process.env.PUBLIC_URL}/images/jonghwikim.webp`}
            alt='profileImage'
          />
        </div>
      </div>
      {maxWidth >= 1024 ? null : <Footer />}
    </>
  )
}

export default About
