import { techURLs } from './techs'
import { overviews } from './overviews'
import { challengeAndSolution } from './challengeAndSolution'

const getURLs = (fileName) => {
  const urls = [...new Array(3).keys()].map((index) => {
    return `${process.env.PUBLIC_URL}/images/${fileName}/${fileName}${index}.png`
  })

  return urls
}

const data = [
  {
    // File directory url
    url: getURLs('next-level'),
    title: 'Next Level Complete Auto Care',
    // URL id
    id: 'next-level-complete-auto-care',
    badges: ['HTML', 'CSS', 'JAVASCRIPT', 'TAILWIND CSS'],
    contents: {
      desc: (
        <div className='ml-2'>
          {overviews['nextLevel'] ||
            [].map((desc, index) => {
              return <div key={index}>{desc}</div>
            })}
        </div>
      ),
      challengeAndSolution: (
        <div>
          {challengeAndSolution['nextLevel'] ||
            [].map((desc, index) => {
              return <div key={index}>{desc}</div>
            })}
        </div>
      ),
      results: [
        <ul className='space-y-2 mt-2 list-disc'>
          <li>
            {' '}
            Achieved and consistently maintained #1 local search rankings for
            key auto repair keywords, resulting in a steady flow of customer
            inquiries.
          </li>
          <li>
            {' '}
            Increased website traffic and customer engagement, contributing to a
            significant rise in revenue for the business.
          </li>
        </ul>,
      ],
      techs: [
        techURLs.html,
        techURLs.css,
        techURLs.javascript,
        techURLs.tailwind,
      ],
      github: 'https://github.com/bellhwi/next-level-complete-auto-care-center',
      website: 'https://nextlevelcompleteauto.com/',
    },
  },
  {
    url: getURLs('specialized-auto-tint'),
    title: 'Specialized Auto Tint',
    id: 'specialized-auto-tint',
    badges: ['REACT', 'NEXTJS', 'TAILWIND CSS'],
    contents: {
      desc: (
        <div className='ml-2'>
          {overviews['specializedAutoTint'] ||
            [].map((desc, index) => {
              return <div key={index}>{desc}</div>
            })}
        </div>
      ),
      challengeAndSolution: (
        <div>
          {challengeAndSolution['specializedAutoTint'] ||
            [].map((desc, index) => {
              return <div key={index}>{desc}</div>
            })}
        </div>
      ),
      results: [
        <ul className='space-y-2 mt-2 list-disc'>
          <li>
            {' '}
            Delivered a website that perfectly matched the client’s vision,
            resulting in high client satisfaction and an engaging, professional
            user experience.
          </li>
          <li>
            {' '}
            The project showcased responsive design and seamless user
            interactions, improving the client’s digital presence.
          </li>
        </ul>,
      ],
      techs: [techURLs.react, techURLs.nextjs, techURLs.tailwind],
      github: 'https://github.com/bellhwi/specialized-auto-tint',
      website: 'https://www.s-worktint.com/',
    },
  },
  {
    url: getURLs('jun-law-firm'),
    title: 'Law Office of Hong-min Jun P.C',
    id: 'law-office-of-hong-min-jun-pc',
    badges: ['HTML', 'CSS', 'JAVASCRIPT', 'TAILWIND CSS'],
    contents: {
      desc: (
        <div className='ml-2'>
          {overviews['junLawFirm'] ||
            [].map((desc, index) => {
              return <div key={index}>{desc}</div>
            })}
        </div>
      ),
      challengeAndSolution: (
        <div>
          {challengeAndSolution['junLawFirm'] ||
            [].map((desc, index) => {
              return <div key={index}>{desc}</div>
            })}
        </div>
      ),
      results: [
        <ul className='space-y-2 mt-2 list-disc'>
          <li>
            {' '}
            Delivered a modern, user-friendly website that enhanced the firm's
            online presence and made content updates effortless for the client.
          </li>
          <li>
            {' '}
            The client was highly satisfied with the design and the ease of
            updating the blog posts, which contributed to improved client
            engagement.
          </li>
        </ul>,
      ],
      techs: [
        techURLs.html,
        techURLs.css,
        techURLs.javascript,
        techURLs.tailwind,
      ],
      github: 'https://github.com/bellhwi/junlawfirm',
      website: 'https://junlawfirm.com/',
    },
  },
]

export { data }
