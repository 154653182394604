import { useNavigate } from 'react-router-dom'
import { data } from '../data'

function Thumbnail({ url, index, width }) {
  const navigate = useNavigate()

  // Ensure data[index] is defined
  const item = data[index] || {}

  return (
    <div
      className='thumbnail relative cursor-pointer flex flex-col shadow-lg p-4'
      onClick={() => {
        navigate(`/${item.id}`)
      }}
    >
      <img
        src={url}
        draggable={false}
        className='mx-auto'
        style={{ width: `${width}` }}
      />
      <div className='mt-2'>
        {(item.badges || []).map((badge, i) => {
          return (
            <div
              key={i}
              className={`badge badge-outline ${badge} badge-sm m-0.5`}
            >
              {badge}
            </div>
          )
        })}
        <h1 className='mt-1 text-gray-600 text-xs sm:text-sm'>{item.title}</h1>
      </div>
    </div>
  )
}

export default Thumbnail
