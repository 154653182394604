const challengeAndSolution = {
  nextLevel: [
    <ul className='space-y-2 mt-2 list-disc'>
      <li>
        <b>Challenge:</b> The shop needed a highly visible website to stand out
        in a competitive local market and attract more customers.
      </li>
      <li>
        <b>Solution:</b> Implemented a combination of on-page SEO and Google Ads
        PPC to improve the shop’s search rankings, while ensuring a
        user-friendly, responsive website that highlights the business's
        strengths.
      </li>
    </ul>,
  ],
  specializedAutoTint: [
    <ul className='space-y-2 mt-2 list-disc'>
      <li>
        <b>Challenge:</b> The client required a completely custom-built website
        that went beyond typical templates, with a strong emphasis on design
        aesthetics and functionality.
      </li>
      <li>
        <b>Solution:</b> Worked closely with the client to understand their
        design preferences and built the site from scratch using React and
        Next.js, ensuring the final product met the client's high standards for
        both design and performance.
      </li>
    </ul>,
  ],
  junLawFirm: [
    <ul className='space-y-2 mt-2 list-disc'>
      <li>
        <b>Challenge:</b> The client needed a website redesign to better
        highlight their legal expertise and achievements while allowing for easy
        blog content updates.
      </li>
      <li>
        <b>Solution:</b> Redesigned the website using HTML, CSS, and JavaScript,
        and implemented a blog feature that has positively impacted SEO by
        regularly updating content based on client inputs.
      </li>
    </ul>,
  ],
}

export { challengeAndSolution }
