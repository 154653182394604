const overviews = {
  nextLevel: [
    <p className='mt-2'>
      Developed a business website for an auto repair shop, aimed at boosting
      online visibility and attracting more customers. The website leveraged SEO
      and PPC strategies to increase traffic and maintain a leading presence in
      local search results.
    </p>,
  ],
  specializedAutoTint: [
    <p className='mt-2'>
      Developed a fully custom website for an auto tinting shop, collaborating
      closely with the client, a former UI/UX designer, to deliver a unique and
      personalized design.
    </p>,
  ],
  junLawFirm: [
    <p className='mt-2'>
      Designed and developed a professional website for a law firm, focusing on
      showcasing the firm's expertise and making the site user-friendly for easy
      content management.
    </p>,
  ],
}

export { overviews }
